//
// Pricing
// --------------------------------------------------


// Price switcher
.cs-price-switch {
  display: flex;
  align-items: center;

  .form-switch {
    margin: 0;
    padding: 0;
    
    .form-check-input {
      width: 3rem;
      height: 1.5rem;
      margin: 0;
      background-color: rgba($gray-600, 0.25);
      border-color: transparent;
      cursor: pointer;

      &:checked {
        background-color: $primary;
        border-color: $primary;
      }

      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    .form-check-label {
      display: none;
    }
  }
}

.cs-price-label {
  transition: $price-switch-label-transition;
  color: $price-switch-label-color;
  @include font-size($price-switch-label-font-size);
  font-weight: $price-switch-label-font-weight;

  &:first-of-type {
    color: $price-switch-label-active-color;
  }
}

.cs-price-switch-on {
  .cs-price-label:first-of-type {
    color: $price-switch-label-color;
  }
  .cs-price-label:last-of-type {
    color: $price-switch-label-active-color;
  }
}
