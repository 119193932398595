// Mixin's update

// When updateing make sure to update bg-gradient in @mixins

// Fonts

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #012140;
  font-family: $font-family-mono;
  line-height: 1 !important;
}

h1,
h2,
h3 {
  letter-spacing: 0px;
}

body {
  color: #012140;
}

.text-white {
  color: rgba(255, 255, 255, 0.92) !important;
}

.text-secondary {
  color: #d2d6da !important;
}

.display-xl {
  font-size: 3.5rem;
}

.alt-text {
  font-family: $font-family-mono;
  @media (max-width: 576px) {
    font-size: 3vw;
  }
}

.marker-text {
  font-family: $font-family-marker;
  @media (max-width: 576px) {
    font-size: 3vw;
  }
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.highlighter-underline {
  background: url("/assets/img/background-assets/highlight-splat-yellow-2.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlighter-underline.green {
  background: url("/assets/img/background-assets/highlight-splat-green.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlight-container-new, .highlight-new {
  position: relative;
}

.highlight-container-new {
  display: inline-block;
}
.highlight-container-new:before {
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: $primary !important;
  opacity: .75;
  transform: rotate(2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
}

.sub-header {
  letter-spacing: -0.007em;
  margin-bottom: 25px;
  line-height: 1.7;
}

.expose-background-image {
  &.splats {
    background-image: url(/assets/img/background-assets/splats-overlap-gradient-bg-text.png);
    background-position: 0px 0px;
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.game {
    background-image: url(/assets/img/graphics/bugsplat-game-background-image.png);
    background-position: 0px 0px;
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.card-title {
  font-family: $font-family-base
}

// Spinner Customization

.spinner-border.text-primary {
  color: #007bfd !important;
}

// Link Customizations

.social-link:hover {
  color: #141e29;
  text-decoration: none;
}

.primary-link {
  color: #007bfd;
  text-decoration: underline;
}

.primary-link:hover {
  color: #9e9fb4 !important;
  text-decoration: underline;
}

.dark-link:hover {
  color: #9e9fb4 !important;
  text-decoration: none;
}

.fe-chevron-right:before {
  display: inline-block;
  text-decoration: none;
}

a:hover {
  color: $gray-900;
  text-decoration: none;
}

a.social-btn.sb-outline.sb-dark:hover {
  color: #ffffff !important;
}

//Button customizations
.btn {
  -webkit-appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-primary:hover {
  color: #fff !important;
  opacity: 0.8;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

.effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  width: 170px;
  border-radius: $border-radius;
}

.curved-header {
  top: 0;
  left: 0;
  height: 635px;

  @media (min-width: 993px) {
    height: 655px;
  }

  @media (max-width: 992px) {
    height: 567px;
  }
  @media (max-width: 768px) {
    height: 800px;
  }
  @media (max-width: 576px) {
    height: 727px;
  }
}

.reverse-hover {
  &:hover {
    color: $white !important;
  }
}

// Navbar Custom SCSS

.navbar-expand-lg {
  .dropdown-mega {
    .dropdown-column {
      @media (min-width: 992px) {
        display: block;
        width: auto !important;
        min-width: auto !important;
        padding: 1.5rem 0.75rem;
        background-color: rgba(255, 255, 255, 0.97);
        border-color: rgba(255, 255, 255, 0.97);
      }
    }
  }
}

.navbar-expand-lg {
  .dropdown-menu {
    @media (min-width: 992px) {
      background-color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
    }
  }
}

.cs-offcanvas-collapse {
  @media (max-width: 991.98px) {
    background-color: rgba(255, 255, 255, 0.97);
  }
}

.navbar-sticky.navbar-stuck {
  background-color: $gray-900;
  color: $white;
}

.nav-icon {
  width: 42px;
  padding: 6px;
  &:hover {
    filter: invert(6%) sepia(8%) saturate(7330%) hue-rotate(192deg) brightness(94%) contrast(89%);
  }
}

.dropdown-toggle {
  &::after {
    margin-left: 0.25rem;
    content: "\e930";
  }
}

.nav-link-style:hover {
  .nav-icon {
    filter: invert(6%) sepia(8%) saturate(7330%) hue-rotate(192deg) brightness(94%) contrast(89%);
  }
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: $gray-800 !important;
}

.menu-card {
  @media screen and (max-width: 768px) {
    &.dropdown-menu-width {
      max-width: 275px;
    }
  }
}

.menu-card:hover {
  background: #d5e2f8;
  text-decoration: none !important;
  border-color: $primary !important;
  .font-size-lg.text-dark {
    color: $primary !important;
  }
  .text-muted.small {
    color: $blue-indigo !important;
  }
}

.display-none-md {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.media-body {
  &.collapse-menu-gutter {
    @media screen and (max-width: 768px) {
      max-width: 275px;
    }
  }
}

// Footer Customization

.footer-icon {
  padding: 11px;
  line-height: 2.625rem;
  &.one-percent {
    height: auto;
    max-width: 80px;
    padding-top: 145px;
    @media screen and (max-width: 991px) {
      max-width: 70px;
      padding-top: 45px;
    }
  }
}

.cs-widget-light {
  .cs-widget-link {
    &:hover {
      color: $gray-900 !important;
    }
  }
}

.cs-widget-link {
  &.reverse-hover {
    &:hover {
      color: $white !important;
    }
  }
}

.sb-mail {
  &:hover {
    color: $primary;
  }
}

.sb-discord {
  &.sb-light {
    height: auto;
    width: 44px;
    vertical-align: top;

    &:hover {
      border-color: gray;
      background: gray;
      .footer-icon {
        filter: invert(53%) sepia(68%) saturate(462%) hue-rotate(192deg) brightness(91%) contrast(86%);
      }
    }
  }

  &.sb-dark {
    height: auto;
    width: 44px;
    vertical-align: top;
    .footer-icon {
      filter: invert(42%) sepia(15%) saturate(387%) hue-rotate(169deg) brightness(98%) contrast(86%);
    }

    &:hover {
      border-color: #7289da;
      background: #7289da;
      .footer-icon {
        filter: none;
      }
    }
  }
}

.sb-bluesky {
  &.sb-light {
    height: auto;
    width: 44px;
    vertical-align: top;

    &:hover {
      border-color: gray;
      background: gray;
      .footer-icon {
        filter: invert(61%) sepia(90%) saturate(1892%) hue-rotate(194deg) brightness(96%) contrast(92%);
      }
    }
  }

  &.sb-dark {
    height: auto;
    width: 44px;
    vertical-align: top;
    .footer-icon {
      filter: invert(60%) sepia(41%) saturate(1695%) hue-rotate(190deg) brightness(90%) contrast(85%);
    }

    &:hover {
      border-color: #1da1f2;
      background: #1da1f2; 
      .footer-icon {
        filter: none;
      }
    }
  }
}

.sb-mail.sb-dark:hover {
  color: #fff;
  border-color: transparent;
  background: #007bfd;
}

// Documentation Styles Update

.cs-offcanvas {
  .cs-offcanvas-body {
    .nav-link-style {
      color: $blue-indigo;
      font-weight: 500;
      &:hover {
        color: $blue-indigo-hover;
      }
    }
  }
  .cs-offcanvas-enabled {
    .navbar-expand.navbar {
      background-color: $gray-400 !important;
    }
  }
}

.bs-doc-body {
  h2,
  h3,
  h4,
  h5 {
    font-weight: $font-weight-bold;
    padding-bottom: 0.5rem;
    padding-top: 2rem;
    &:first-child {
    }
  }

  p {
    font-size: $font-size-md !important;
  }

  .img-fluid {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  li {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .gist-file {
    margin-top: 2em;
  }
}

.platforms-download {
  background: #ffffff;
  border: 1px solid #d5dce6;
  border-radius: 4px;
  margin-bottom: 30px;

  &.otherlink {
    &:hover {
      transition: all 0.2s;
      cursor: pointer;
      border-color: #2561cc;
      text-decoration: none !important;

      .title {
        color: #2561cc !important;
      }
    }

    a {
      &:focus {
        text-decoration: none !important;
      }

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .header {
    display: flex;
    // flex-direction: column;
    align-items: center;
    padding: 30px 10px 20px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: initial;
      padding: 0;
      padding-top: 10px;
    }

    .contentLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;

      @media screen and (min-width: 768px) {
        width: 100px;
        min-height: 100px;
      }

      @media screen and (max-width: 992px) {
        margin-right: 1em;
        margin-left: 0.5em;
      }

      .icon-circle {
        border-radius: 50%;
        height: 56px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;

        @media (max-width: 1240px) and (min-width: 992px) {
          height: 40px;
          width: 40px;
        }

        @media (max-width: 720px) and (min-width: 540px) {
          height: 40px;
          width: 40px;
        }

        img.icon-img {
          margin: 0 auto;
          max-width: 50%;
          max-height: 50%;
          filter: brightness(0) invert(1);
        }
      }

      .fa {
        width: auto;
        margin-right: inherit;
        -webkit-font-smoothing: antialiased;
      }
    }

    .contentRight {
      text-align: initial;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding-right: 15px;

      .title {
        font-size: 18px;
        color: #333c48;
        margin-bottom: 5px;
        font-weight: 600;
        margin-top: 0px;
      }
      .info {
        font-size: 16px;
        color: #7f8fa4;
        line-height: 24px;
      }
    }
  }

  .links {
    display: flex;
    align-items: center;
    text-align: center;
    border-top: 1px solid #d5dce6;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .platforms {
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      color: $black;
      line-height: 24px;
      font-weight: 600;
      width: 100%;

      &.left {
        border-top: none;
        border-left: 1px solid #d5dce6;
      }

      &:hover {
        color: #fff;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

// Blog Custom SCSS

.blog-body {
  h2,
  h3,
  h4,
  h5 {
    font-weight: $font-weight-bold;
    padding-bottom: 0.5rem;
    padding-top: 2rem;
    &:first-child {
    }
  }

  p {
    font-size: $font-size-xl !important;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  @media (min-width: 768px) {
    max-width: 877px;
  }

  ul {
    li {
      font-size: $font-size-xl !important;
    }
  }

  .blog-thumbnail {
    max-height: 500px;
  }

  figure {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.card {
  &.blog-head {
    border: none !important;
  }
}

.no-border {
  border: none;
}

.card-horizontal {
  .card-img-top {
    @media (min-width: 576px) {
      width: 33%;
    }
  }
}

.meta-link.font-size-xs {
  font-weight: 400;
}

// Hover / active state

.card-hover {
  transition: $card-transition;
}
.card.card-hover:hover,
.card.card-active {
  border-color: none !important;
  @include box-shadow($card-active-box-shadow !important);
}

// Icon styles for Integrations grid

.tab-icon {
  background-color: white;
  padding: 1em;
  border-radius: 1em;

  &.gray {
    background-color: $gray-200;
  }
  .icon-img {
    width: 33px;
    max-width: 33px;
    min-width: 30px;
  }
}

// Class for Brand Icons

.icon-text-line-break {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

// Icon Styles for Splat icons

.splat-icon {
  width: 22px;
  height: 22px;
}

// Form updates

.form-label {
  padding-left: 0px;
  font-weight: 700 !important;
  font-size: large;
}

// Index page style updates

.overflow-splat {
  @media (max-width: 1059px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.card-for-bugsplat-min-height {
  @media (min-width: 1270px) {
    min-height: 640px;
  }
  @media (max-width: 1159px) {
    min-height: 630px;
  }
  @media (max-width: 991px) {
    min-height: 692px;
  }
  @media (max-width: 772px) {
    min-height: 630px;
  }
  @media (max-width: 645px) {
    min-height: 573px;
  }
}

.card-thumbnail {
  max-width: 250px;
  margin: 0 auto !important;
  float: none !important;
}

.avatar {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.splat-quote {
  position: relative;
  display: inline-block;
  margin: 0px;
  z-index: 100;

  span {
    z-index: 1;
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: url("/assets/img/icons/bg-splat-blue.svg") no-repeat scroll left top;
    height: 300px;
    left: -64px;
    width: 200px;
    top: -60px;
    z-index: -119;
  }
  &:after {
    @media (max-width: 1060px) {
      display: none;
    }
    content: "";
    display: block;
    position: absolute;
    background: url("/assets/img/icons/bg-splat-pink.svg") no-repeat scroll left top;
    height: 164px;
    right: -101px;
    width: 161px;
    z-index: -100;
    top: 300px;
  }
}

.splat-quote-2 {
  position: relative;
  display: inline-block;
  margin: 0px;
  z-index: 100;

  span {
    z-index: 1;
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: url("/assets/img/icons/bg-splat-green.svg") no-repeat scroll left top;
    height: 216px;
    left: -75px;
    width: 200px;
    top: -60px;
    z-index: -119;
  }
  &:after {
    @media (max-width: 1060px) {
      display: none;
    }
    content: "";
    display: block;
    position: absolute;
    background: url("/assets/img/icons/bg-splat-yellow.svg") no-repeat scroll left top;
    height: 171px;
    right: -8px;
    width: 118px;
    z-index: -100;
    top: 211px;
  }
}

.splat-title {
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 0px;

  span {
    z-index: 1;
    position: relative;
  }

  &:before {
    @media (max-width: 1060px) {
      top: 3px;
      width: 125px;
      left: 14px;
    }
    content: "";
    display: block;
    position: absolute;
    background: url("/assets/img/icons/bg-splat-yellow-horizontal.svg") no-repeat scroll left top;
    height: 111px;
    left: 154px;
    width: 75px;
    top: 49px;
  }
  &:after {
    @media (max-width: 1060px) {
      display: none;
    }
    content: "";
    display: block;
    position: absolute;
    background: url("/assets/img/icons/bg-splat-green-horizontal.svg") no-repeat scroll left top;
    height: 111px;
    right: -62px;
    top: 47px;
    width: 152px;
  }
}

.small-bg-splat-yellow :before {
  content: "";
  display: block;
  position: absolute;
  background: url(/static/sparkle-right-51e36ac….gif) left top / 75px 111px no-repeat;
  height: 111px;
  right: 0px;
  top: -40px;
  width: 75px;
}

.small-bg-splat-green :before {
  content: "";
  display: block;
  position: absolute;
  background: url(/static/sparkle-right-51e36ac….gif) left top / 75px 111px no-repeat;
  height: 111px;
  right: 0px;
  top: -40px;
  width: 75px;
}

.index-image {
  &.first {
    @media (min-width: 1200px) {
      width: 733px;
      left: -204px;
      top: 39px;
    }
    img {
      @media (max-width: 992px) {
        max-height: 400px;
      }
    }
  }

  &.second {
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 575px;
      left: -56px;
      z-index: 10;
      top: 0px;
    }
    @media (min-width: 1200px) {
      width: 700px;
      left: -70px;
      z-index: 10;
      top: 0px;
    }
    img {
      @media (max-width: 992px) {
        max-height: 400px;
      }
    }
  }

  &.third {
    @media (min-width: 1200px) {
      width: 733px;
      left: -204px;
      top: 0px;
    }
    img {
      @media (max-width: 992px) {
        max-height: 400px;
      }
    }
  }

  &.fourth {
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 654px;
      left: -70px;
      z-index: 10;
      top: 0px;
    }

    @media (min-width: 1200px) {
      width: 650px;
      left: -5px;
      z-index: 10;
      top: 4px;
    }
    img {
      @media (max-width: 992px) {
        max-height: 475px;
      }
    }
  }
}

// Pricing Table Customization

.min-price-table-height {
  min-height: 344px;
}

.badge-top {
  position: absolute;
  top: -0.75rem;
  bottom: auto;
  z-index: 10;
}

.accordion .accordion-heading > a.collapsed {
  color: #ffffff !important;
}

.cs-price-label {
  color: $gray-500;
}

.cs-price-label:first-of-type {
  color: $white;
}

.cs-price-switch-on {
  .cs-price-label:last-of-type {
    color: $white;
  }
}

// Cards styling for Plans page

.card-group {
  &.plans-cards {
    @media (max-width: 859px) {
      display: block !important;
      flex-flow: nowrap;
    }
    .card {
      @media (min-width: 858px) {
        margin-bottom: 25px !important;
      }
      &.border {
        @media (max-width: 859px) {
          border-radius: 16px 16px 0 0 !important;
        }
        .card-image-top.custom-border {
          @media (max-width: 859px) {
            border-radius: 16px 16px 0 0 !important;
          }
        }
      }
    }
  }
}

.card-group.plans-cards {
  .card {
    @media (max-width: 858px) {
      margin-bottom: 25px !important;
    }
  }
}

.card-group > .card:not(:first-child) .card-img-top {
  @media (max-width: 859px) {
    border-top-left-radius: 12px;
  }
}

.card-group > .card:not(:last-child) .card-img-top {
  @media (max-width: 859px) {
    border-top-right-radius: 12px;
  }
}

// Cards styling for index feature highlight section

.platform-icon {
  width: 30px;
  margin: 0 4px;
}

.card-wrapper {
  position: relative;
}

.card {
  &.overlap-right {
    position: absolute;
    top: 22%;
    left: 90%;
    width: 569px;
  }
  &.overlap-left {
    position: absolute;
    top: 22%;
    width: 569px;
  }
  &.underlap-left {
    left: 73%;
  }
  &.remove-border {
    border: none !important;
  }
  &.border-radius-none {
    border-radius: 2px !important;
  }
}

.card-body {
  &.box-shadow-lg {
    border-radius: $border-radius-lg;
  }
}

// Background asset display classes

.bg-overlay-transparent {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bg-gradient-night {
  background: $black;
  background: linear-gradient(180deg, $black 0%, $gray-900 53%, rgba(68, 84, 118, 1) 78%, rgba(255, 255, 255, 1) 100%);
}
.bg-gradient-off-white {
  background: $white;
  background: linear-gradient(180deg, #f7fbff 0%, #f7fbff 53%, #f7fbff 78%, #fff 100%);
}

.bg-gray {
  background-color: $gray-100;
}

.bg-docs-header {
  background-color: $gray-100 !important;
}

.bg-faded-dark {
  background-color: #122e4b !important;
}

.bg-faded-success {
  background: linear-gradient(rgba(10, 166, 124, 0.65), rgba(10, 166, 124, 0.65)), linear-gradient(#012140, #012140); /* first bg is on top of this */
}

.bg-faded-primary {
  background: linear-gradient(rgba(0, 123, 253, 0.65), rgba(0, 123, 253, 0.65)), linear-gradient(#012140, #012140); /* first bg is on top of this */
}

.bg-faded-info {
  background: linear-gradient(rgba(116, 95, 222, 0.65), rgba(116, 95, 222, 0.65)), linear-gradient(#012140, #012140); /* first bg is on top of this */
}

.bg-faded-warning {
  background: linear-gradient(rgba(243, 196, 63, 0.65), rgba(243, 196, 63, 0.65)), linear-gradient(#012140, #012140); /* first bg is on top of this */
}

.bg-faded-danger {
  background: linear-gradient(rgba(205, 43, 81, 0.65), rgba(205, 43, 81, 0.65)), linear-gradient(#012140, #012140); /* first bg is on top of this */
}

.cs-shape.bg-body {
  color: #ffffff !important;
}

//- New Rounding Rules 

.rounded-start-0{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

// Feature Highlights Backgrounds

.top-0 {
  top: 0;
  bottom: auto;
}

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.decoration:not(.position-relative) {
  position: absolute;
}

.decoration png {
  background: none !important;
}

.decoration.top {
  top: 0;
}

.decoration.left {
  left: 0;
}

.decoration.right {
  right: 0;
}

.decoration.middle-y {
  top: 50%;
}

.decoration.middle-x {
  left: 50%;
}

.decoration.bottom {
  bottom: 0;
}

.decoration-check {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.decoration-check .decoration {
  position: relative;
  max-width: 250px;
}
.decoration-check .icon {
  position: absolute;
  height: 4rem;
}

.scale-2 {
  transform: scale(2);
}

.scale-3 {
  transform: scale(3);
}

.scale-4 {
  transform: scale(4);
}

.scale-5 {
  transform: scale(5);
}

/* Custom CSS for Brand Page */
/* Logo series wrapper */
.bs-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  color: #0f204b;
  background-color: #f9fbfd;
  border-radius: 4px;
}

.bs-brand-logos-white {
  display: table;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  color: #fff;
  background-color: #fff;
  border-radius: 4px;
}

/* Individual items */
.bs-brand-item {
  padding: 60px 0;
  text-align: center;
  font-family: helvetica nue;
}

.bs-brand-item + .bs-brand-item {
  border-top: 1px solid #fff;
}

.bs-brand-logos .inverse {
  color: #fff;
  background-color: $gray-900;
}

.bs-brand-logos .inverse-white {
  color: #fff;
  background-color: #fff;
}

/* Heading content within */
.bs-brand-item h1,
.bs-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-family-base;
}

.bs-brand-item .bs-docs-booticon {
  margin-right: auto;
  margin-left: auto;
}

/* Make the icons stand out on what is/isn't okay */
.bs-brand-item .fa {
  width: 30px;
  height: 30px;
  margin: 10px auto -10px;
  line-height: 30px;
  color: #fff;
  border-radius: 50%;
}

.bs-brand-item .fa-check-circle {
  color: $success;
}

.bs-brand-item .fa-times-circle {
  color: $danger;
}

@media (min-width: 768px) {
  .bs-brand-item {
    display: table-cell;
    width: 1%;
  }

  .bs-brand-item + .bs-brand-item {
    border-top: 0;
    border-left: 1px solid #fff;
  }

  .bs-brand-item h1 {
    font-size: 60px;
  }
}

/*
* Color swatches
*
* Color swatches and associated values for our grayscale and brand colors.
*/

.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
  /* clearfix */
}

.color-swatch {
  float: left;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .color-swatch {
    width: 100px;
    height: 100px;
    margin-top: 1em;
  }
}

/* Docs colors */
.color-swatches .bs-dark-blue {
  background-color: $gray-900;
}

.color-swatches .bs-blue-light {
  background-color: $primary;
}

.color-swatches .bs-secondary {
  background-color: $secondary;
}

.color-swatches .bs-blue-lightest {
  background-color: $gray-100;
}

.color-swatches .bs-yellow-lighter {
  background-color: $warning;
}

.color-swatches .bs-green {
  background-color: $success;
}

.color-swatches .bs-pink {
  background-color: $bs-danger;
}

//
// user.scss
// Use this to write your custom SCSS
//

// Added extra classes to default set of Bootstrap



.m-8 {
  margin: 3rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3rem !important;
}

.me-8,
.mx-8 {
  margin-right: 3rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3rem !important;
}

.ms-8,
.mx-8 {
  margin-left: 3rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.me-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ms-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 5rem !important;
}

.me-10,
.mx-10 {
  margin-right: 5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 5rem !important;
}

.ms-10,
.mx-10 {
  margin-left: 5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.mt-11,
.my-11 {
  margin-top: 6rem !important;
}

.me-11,
.mx-11 {
  margin-right: 6rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 6rem !important;
}

.ms-11,
.mx-11 {
  margin-left: 6rem !important;
}

.m-12 {
  margin: 8rem !important;
}

.mt-12,
.my-12 {
  margin-top: 8rem !important;
}

.me-12,
.mx-12 {
  margin-right: 8rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 8rem !important;
}

.ms-12,
.mx-12 {
  margin-left: 8rem !important;
}

.m-13 {
  margin: 10rem !important;
}

.mt-13,
.my-13 {
  margin-top: 10rem !important;
}

.me-13,
.mx-13 {
  margin-right: 10rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 10rem !important;
}

.ms-13,
.mx-13 {
  margin-left: 10rem !important;
}

.m-14 {
  margin: 12rem !important;
}

.mt-14,
.my-14 {
  margin-top: 12rem !important;
}

.me-14,
.mx-14 {
  margin-right: 12rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 12rem !important;
}

.ms-14,
.mx-14 {
  margin-left: 12rem !important;
}

.m-15 {
  margin: 16rem !important;
}

.mt-15,
.my-15 {
  margin-top: 16rem !important;
}

.me-15,
.mx-15 {
  margin-right: 16rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 16rem !important;
}

.ms-15,
.mx-15 {
  margin-left: 16rem !important;
}

.m-16 {
  margin: 25rem !important;
}

.mt-16,
.my-16 {
  margin-top: 25rem !important;
}

.me-16,
.mx-16 {
  margin-right: 25rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 25rem !important;
}

.ms-16,
.mx-16 {
  margin-left: 25rem !important;
}

.p-8 {
  padding: 3rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3rem !important;
}

.pe-8,
.px-8 {
  padding-right: 3rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3rem !important;
}

.ps-8,
.px-8 {
  padding-left: 3rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pe-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.ps-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 5rem !important;
}

.pe-10,
.px-10 {
  padding-right: 5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 5rem !important;
}

.ps-10,
.px-10 {
  padding-left: 5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.pt-11,
.py-11 {
  padding-top: 6rem !important;
}

.pe-11,
.px-11 {
  padding-right: 6rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 6rem !important;
}

.ps-11,
.px-11 {
  padding-left: 6rem !important;
}

.p-12 {
  padding: 8rem !important;
}

.pt-12,
.py-12 {
  padding-top: 8rem !important;
}

.pe-12,
.px-12 {
  padding-right: 8rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 8rem !important;
}

.ps-12,
.px-12 {
  padding-left: 8rem !important;
}

.p-13 {
  padding: 10rem !important;
}

.pt-13,
.py-13 {
  padding-top: 10rem !important;
}

.pe-13,
.px-13 {
  padding-right: 10rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 10rem !important;
}

.ps-13,
.px-13 {
  padding-left: 10rem !important;
}

.p-14 {
  padding: 12rem !important;
}

.pt-14,
.py-14 {
  padding-top: 12rem !important;
}

.pe-14,
.px-14 {
  padding-right: 12rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 12rem !important;
}

.ps-14,
.px-14 {
  padding-left: 12rem !important;
}

.p-15 {
  padding: 16rem !important;
}

.pt-15,
.py-15 {
  padding-top: 16rem !important;
}

.pe-15,
.px-15 {
  padding-right: 16rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 16rem !important;
}

.ps-15,
.px-15 {
  padding-left: 16rem !important;
}

.p-16 {
  padding: 25rem !important;
}

.pt-16,
.py-16 {
  padding-top: 25rem !important;
}

.pe-16,
.px-16 {
  padding-right: 25rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 25rem !important;
}

.ps-16,
.px-16 {
  padding-left: 25rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.me-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ms-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.me-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ms-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}

.me-n3,
.mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important;
}

.ms-n3,
.mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1rem !important;
}

.me-n4,
.mx-n4 {
  margin-right: -1rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important;
}

.ms-n4,
.mx-n4 {
  margin-left: -1rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important;
}

.me-n5,
.mx-n5 {
  margin-right: -1.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important;
}

.ms-n5,
.mx-n5 {
  margin-left: -1.5rem !important;
}

.m-n6 {
  margin: -2rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2rem !important;
}

.me-n6,
.mx-n6 {
  margin-right: -2rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important;
}

.ms-n6,
.mx-n6 {
  margin-left: -2rem !important;
}

.m-n7 {
  margin: -2.5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -2.5rem !important;
}

.me-n7,
.mx-n7 {
  margin-right: -2.5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -2.5rem !important;
}

.ms-n7,
.mx-n7 {
  margin-left: -2.5rem !important;
}

.m-n8 {
  margin: -3rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3rem !important;
}

.me-n8,
.mx-n8 {
  margin-right: -3rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3rem !important;
}

.ms-n8,
.mx-n8 {
  margin-left: -3rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.me-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ms-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -5rem !important;
}

.me-n10,
.mx-n10 {
  margin-right: -5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -5rem !important;
}

.ms-n10,
.mx-n10 {
  margin-left: -5rem !important;
}

.m-n11 {
  margin: -6rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -6rem !important;
}

.me-n11,
.mx-n11 {
  margin-right: -6rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -6rem !important;
}

.ms-n11,
.mx-n11 {
  margin-left: -6rem !important;
}

.m-n12 {
  margin: -8rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -8rem !important;
}

.me-n12,
.mx-n12 {
  margin-right: -8rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -8rem !important;
}

.ms-n12,
.mx-n12 {
  margin-left: -8rem !important;
}

.m-n13 {
  margin: -10rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -10rem !important;
}

.me-n13,
.mx-n13 {
  margin-right: -10rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -10rem !important;
}

.ms-n13,
.mx-n13 {
  margin-left: -10rem !important;
}

.m-n14 {
  margin: -12rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -12rem !important;
}

.me-n14,
.mx-n14 {
  margin-right: -12rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -12rem !important;
}

.ms-n14,
.mx-n14 {
  margin-left: -12rem !important;
}

.m-n15 {
  margin: -16rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -16rem !important;
}

.me-n15,
.mx-n15 {
  margin-right: -16rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -16rem !important;
}

.ms-n15,
.mx-n15 {
  margin-left: -16rem !important;
}

.m-n16 {
  margin: -25rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -25rem !important;
}

.me-n16,
.mx-n16 {
  margin-right: -25rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -25rem !important;
}

.ms-n16,
.mx-n16 {
  margin-left: -25rem !important;
}

@media (min-width: 576px) {
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .ms-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ms-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  .me-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  .ms-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  .me-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  .ms-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  .m-3 {
    margin: 0.75rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 0.75rem !important;
  }
  .me-3,
  .mx-3 {
    margin-right: 0.75rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0.75rem !important;
  }
  .ms-3,
  .mx-3 {
    margin-left: 0.75rem !important;
  }
  .m-4 {
    margin: 1rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 1rem !important;
  }
  .me-4,
  .mx-4 {
    margin-right: 1rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1rem !important;
  }
  .ms-4,
  .mx-4 {
    margin-left: 1rem !important;
  }
  .m-5 {
    margin: 1.5rem !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 1.5rem !important;
  }
  .me-5,
  .mx-5 {
    margin-right: 1.5rem !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-5,
  .mx-5 {
    margin-left: 1.5rem !important;
  }
  .m-6 {
    margin: 2rem !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 2rem !important;
  }
  .me-6,
  .mx-6 {
    margin-right: 2rem !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 2rem !important;
  }
  .ms-6,
  .mx-6 {
    margin-left: 2rem !important;
  }
  .m-7 {
    margin: 2.5rem !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 2.5rem !important;
  }
  .me-7,
  .mx-7 {
    margin-right: 2.5rem !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 2.5rem !important;
  }
  .ms-7,
  .mx-7 {
    margin-left: 2.5rem !important;
  }
  .m-8 {
    margin: 3rem !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 3rem !important;
  }
  .me-8,
  .mx-8 {
    margin-right: 3rem !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 3rem !important;
  }
  .ms-8,
  .mx-8 {
    margin-left: 3rem !important;
  }
  .m-9 {
    margin: 4rem !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 4rem !important;
  }
  .me-9,
  .mx-9 {
    margin-right: 4rem !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 4rem !important;
  }
  .ms-9,
  .mx-9 {
    margin-left: 4rem !important;
  }
  .m-10 {
    margin: 5rem !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 5rem !important;
  }
  .me-10,
  .mx-10 {
    margin-right: 5rem !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 5rem !important;
  }
  .ms-10,
  .mx-10 {
    margin-left: 5rem !important;
  }
  .m-11 {
    margin: 6rem !important;
  }
  .mt-11,
  .my-11 {
    margin-top: 6rem !important;
  }
  .me-11,
  .mx-11 {
    margin-right: 6rem !important;
  }
  .mb-11,
  .my-11 {
    margin-bottom: 6rem !important;
  }
  .ms-11,
  .mx-11 {
    margin-left: 6rem !important;
  }
  .m-12 {
    margin: 8rem !important;
  }
  .mt-12,
  .my-12 {
    margin-top: 8rem !important;
  }
  .me-12,
  .mx-12 {
    margin-right: 8rem !important;
  }
  .mb-12,
  .my-12 {
    margin-bottom: 8rem !important;
  }
  .ms-12,
  .mx-12 {
    margin-left: 8rem !important;
  }
  .m-13 {
    margin: 10rem !important;
  }
  .mt-13,
  .my-13 {
    margin-top: 10rem !important;
  }
  .me-13,
  .mx-13 {
    margin-right: 10rem !important;
  }
  .mb-13,
  .my-13 {
    margin-bottom: 10rem !important;
  }
  .ms-13,
  .mx-13 {
    margin-left: 10rem !important;
  }
  .m-14 {
    margin: 12rem !important;
  }
  .mt-14,
  .my-14 {
    margin-top: 12rem !important;
  }
  .me-14,
  .mx-14 {
    margin-right: 12rem !important;
  }
  .mb-14,
  .my-14 {
    margin-bottom: 12rem !important;
  }
  .ms-14,
  .mx-14 {
    margin-left: 12rem !important;
  }
  .m-15 {
    margin: 16rem !important;
  }
  .mt-15,
  .my-15 {
    margin-top: 16rem !important;
  }
  .me-15,
  .mx-15 {
    margin-right: 16rem !important;
  }
  .mb-15,
  .my-15 {
    margin-bottom: 16rem !important;
  }
  .ms-15,
  .mx-15 {
    margin-left: 16rem !important;
  }
  .m-16 {
    margin: 25rem !important;
  }
  .mt-16,
  .my-16 {
    margin-top: 25rem !important;
  }
  .me-16,
  .mx-16 {
    margin-right: 25rem !important;
  }
  .mb-16,
  .my-16 {
    margin-bottom: 25rem !important;
  }
  .ms-16,
  .mx-16 {
    margin-left: 25rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pe-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .ps-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  .pe-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  .ps-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  .pe-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  .ps-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  .p-3 {
    padding: 0.75rem !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 0.75rem !important;
  }
  .pe-3,
  .px-3 {
    padding-right: 0.75rem !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 0.75rem !important;
  }
  .ps-3,
  .px-3 {
    padding-left: 0.75rem !important;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 1rem !important;
  }
  .pe-4,
  .px-4 {
    padding-right: 1rem !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 1rem !important;
  }
  .ps-4,
  .px-4 {
    padding-left: 1rem !important;
  }
  .p-5 {
    padding: 1.5rem !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 1.5rem !important;
  }
  .pe-5,
  .px-5 {
    padding-right: 1.5rem !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-5,
  .px-5 {
    padding-left: 1.5rem !important;
  }
  .p-6 {
    padding: 2rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 2rem !important;
  }
  .pe-6,
  .px-6 {
    padding-right: 2rem !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 2rem !important;
  }
  .ps-6,
  .px-6 {
    padding-left: 2rem !important;
  }
  .p-7 {
    padding: 2.5rem !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 2.5rem !important;
  }
  .pe-7,
  .px-7 {
    padding-right: 2.5rem !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 2.5rem !important;
  }
  .ps-7,
  .px-7 {
    padding-left: 2.5rem !important;
  }
  .p-8 {
    padding: 3rem !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 3rem !important;
  }
  .pe-8,
  .px-8 {
    padding-right: 3rem !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 3rem !important;
  }
  .ps-8,
  .px-8 {
    padding-left: 3rem !important;
  }
  .p-9 {
    padding: 4rem !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 4rem !important;
  }
  .pe-9,
  .px-9 {
    padding-right: 4rem !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 4rem !important;
  }
  .ps-9,
  .px-9 {
    padding-left: 4rem !important;
  }
  .p-10 {
    padding: 5rem !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 5rem !important;
  }
  .pe-10,
  .px-10 {
    padding-right: 5rem !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 5rem !important;
  }
  .ps-10,
  .px-10 {
    padding-left: 5rem !important;
  }
  .p-11 {
    padding: 6rem !important;
  }
  .pt-11,
  .py-11 {
    padding-top: 6rem !important;
  }
  .pe-11,
  .px-11 {
    padding-right: 6rem !important;
  }
  .pb-11,
  .py-11 {
    padding-bottom: 6rem !important;
  }
  .ps-11,
  .px-11 {
    padding-left: 6rem !important;
  }
  .p-12 {
    padding: 8rem !important;
  }
  .pt-12,
  .py-12 {
    padding-top: 8rem !important;
  }
  .pe-12,
  .px-12 {
    padding-right: 8rem !important;
  }
  .pb-12,
  .py-12 {
    padding-bottom: 8rem !important;
  }
  .ps-12,
  .px-12 {
    padding-left: 8rem !important;
  }
  .p-13 {
    padding: 10rem !important;
  }
  .pt-13,
  .py-13 {
    padding-top: 10rem !important;
  }
  .pe-13,
  .px-13 {
    padding-right: 10rem !important;
  }
  .pb-13,
  .py-13 {
    padding-bottom: 10rem !important;
  }
  .ps-13,
  .px-13 {
    padding-left: 10rem !important;
  }
  .p-14 {
    padding: 12rem !important;
  }
  .pt-14,
  .py-14 {
    padding-top: 12rem !important;
  }
  .pe-14,
  .px-14 {
    padding-right: 12rem !important;
  }
  .pb-14,
  .py-14 {
    padding-bottom: 12rem !important;
  }
  .ps-14,
  .px-14 {
    padding-left: 12rem !important;
  }
  .p-15 {
    padding: 16rem !important;
  }
  .pt-15,
  .py-15 {
    padding-top: 16rem !important;
  }
  .pe-15,
  .px-15 {
    padding-right: 16rem !important;
  }
  .pb-15,
  .py-15 {
    padding-bottom: 16rem !important;
  }
  .ps-15,
  .px-15 {
    padding-left: 16rem !important;
  }
  .p-16 {
    padding: 25rem !important;
  }
  .pt-16,
  .py-16 {
    padding-top: 25rem !important;
  }
  .pe-16,
  .px-16 {
    padding-right: 25rem !important;
  }
  .pb-16,
  .py-16 {
    padding-bottom: 25rem !important;
  }
  .ps-16,
  .px-16 {
    padding-left: 25rem !important;
  }
  .m-n1 {
    margin: -0.25rem !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important;
  }
  .me-n1,
  .mx-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ms-n1,
  .mx-n1 {
    margin-left: -0.25rem !important;
  }
  .m-n2 {
    margin: -0.5rem !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important;
  }
  .me-n2,
  .mx-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ms-n2,
  .mx-n2 {
    margin-left: -0.5rem !important;
  }
  .m-n3 {
    margin: -0.75rem !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -0.75rem !important;
  }
  .me-n3,
  .mx-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ms-n3,
  .mx-n3 {
    margin-left: -0.75rem !important;
  }
  .m-n4 {
    margin: -1rem !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -1rem !important;
  }
  .me-n4,
  .mx-n4 {
    margin-right: -1rem !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-n4,
  .mx-n4 {
    margin-left: -1rem !important;
  }
  .m-n5 {
    margin: -1.5rem !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -1.5rem !important;
  }
  .me-n5,
  .mx-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-n5,
  .mx-n5 {
    margin-left: -1.5rem !important;
  }
  .m-n6 {
    margin: -2rem !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -2rem !important;
  }
  .me-n6,
  .mx-n6 {
    margin-right: -2rem !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -2rem !important;
  }
  .ms-n6,
  .mx-n6 {
    margin-left: -2rem !important;
  }
  .m-n7 {
    margin: -2.5rem !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -2.5rem !important;
  }
  .me-n7,
  .mx-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ms-n7,
  .mx-n7 {
    margin-left: -2.5rem !important;
  }
  .m-n8 {
    margin: -3rem !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -3rem !important;
  }
  .me-n8,
  .mx-n8 {
    margin-right: -3rem !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -3rem !important;
  }
  .ms-n8,
  .mx-n8 {
    margin-left: -3rem !important;
  }
  .m-n9 {
    margin: -4rem !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -4rem !important;
  }
  .me-n9,
  .mx-n9 {
    margin-right: -4rem !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -4rem !important;
  }
  .ms-n9,
  .mx-n9 {
    margin-left: -4rem !important;
  }
  .m-n10 {
    margin: -5rem !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -5rem !important;
  }
  .me-n10,
  .mx-n10 {
    margin-right: -5rem !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-n10,
  .mx-n10 {
    margin-left: -5rem !important;
  }
  .m-n11 {
    margin: -6rem !important;
  }
  .mt-n11,
  .my-n11 {
    margin-top: -6rem !important;
  }
  .me-n11,
  .mx-n11 {
    margin-right: -6rem !important;
  }
  .mb-n11,
  .my-n11 {
    margin-bottom: -6rem !important;
  }
  .ms-n11,
  .mx-n11 {
    margin-left: -6rem !important;
  }
  .m-n12 {
    margin: -8rem !important;
  }
  .mt-n12,
  .my-n12 {
    margin-top: -8rem !important;
  }
  .me-n12,
  .mx-n12 {
    margin-right: -8rem !important;
  }
  .mb-n12,
  .my-n12 {
    margin-bottom: -8rem !important;
  }
  .ms-n12,
  .mx-n12 {
    margin-left: -8rem !important;
  }
  .m-n13 {
    margin: -10rem !important;
  }
  .mt-n13,
  .my-n13 {
    margin-top: -10rem !important;
  }
  .me-n13,
  .mx-n13 {
    margin-right: -10rem !important;
  }
  .mb-n13,
  .my-n13 {
    margin-bottom: -10rem !important;
  }
  .ms-n13,
  .mx-n13 {
    margin-left: -10rem !important;
  }
  .m-n14 {
    margin: -12rem !important;
  }
  .mt-n14,
  .my-n14 {
    margin-top: -12rem !important;
  }
  .me-n14,
  .mx-n14 {
    margin-right: -12rem !important;
  }
  .mb-n14,
  .my-n14 {
    margin-bottom: -12rem !important;
  }
  .ms-n14,
  .mx-n14 {
    margin-left: -12rem !important;
  }
  .m-n15 {
    margin: -16rem !important;
  }
  .mt-n15,
  .my-n15 {
    margin-top: -16rem !important;
  }
  .me-n15,
  .mx-n15 {
    margin-right: -16rem !important;
  }
  .mb-n15,
  .my-n15 {
    margin-bottom: -16rem !important;
  }
  .ms-n15,
  .mx-n15 {
    margin-left: -16rem !important;
  }
  .m-n16 {
    margin: -25rem !important;
  }
  .mt-n16,
  .my-n16 {
    margin-top: -25rem !important;
  }
  .me-n16,
  .mx-n16 {
    margin-right: -25rem !important;
  }
  .mb-n16,
  .my-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-n16,
  .mx-n16 {
    margin-left: -25rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .me-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ms-auto,
  .mx-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .me-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ms-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .me-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ms-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .me-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ms-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .me-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .ms-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important;
  }
  .me-md-4,
  .mx-md-4 {
    margin-right: 1rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .ms-md-4,
  .mx-md-4 {
    margin-left: 1rem !important;
  }
  .m-md-5 {
    margin: 1.5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important;
  }
  .me-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important;
  }
  .m-md-6 {
    margin: 2rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important;
  }
  .me-md-6,
  .mx-md-6 {
    margin-right: 2rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important;
  }
  .ms-md-6,
  .mx-md-6 {
    margin-left: 2rem !important;
  }
  .m-md-7 {
    margin: 2.5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 2.5rem !important;
  }
  .me-md-7,
  .mx-md-7 {
    margin-right: 2.5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 2.5rem !important;
  }
  .ms-md-7,
  .mx-md-7 {
    margin-left: 2.5rem !important;
  }
  .m-md-8 {
    margin: 3rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3rem !important;
  }
  .me-md-8,
  .mx-md-8 {
    margin-right: 3rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3rem !important;
  }
  .ms-md-8,
  .mx-md-8 {
    margin-left: 3rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .me-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ms-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important;
  }
  .me-md-10,
  .mx-md-10 {
    margin-right: 5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important;
  }
  .ms-md-10,
  .mx-md-10 {
    margin-left: 5rem !important;
  }
  .m-md-11 {
    margin: 6rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 6rem !important;
  }
  .me-md-11,
  .mx-md-11 {
    margin-right: 6rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 6rem !important;
  }
  .ms-md-11,
  .mx-md-11 {
    margin-left: 6rem !important;
  }
  .m-md-12 {
    margin: 8rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 8rem !important;
  }
  .me-md-12,
  .mx-md-12 {
    margin-right: 8rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 8rem !important;
  }
  .ms-md-12,
  .mx-md-12 {
    margin-left: 8rem !important;
  }
  .m-md-13 {
    margin: 10rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 10rem !important;
  }
  .me-md-13,
  .mx-md-13 {
    margin-right: 10rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 10rem !important;
  }
  .ms-md-13,
  .mx-md-13 {
    margin-left: 10rem !important;
  }
  .m-md-14 {
    margin: 12rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 12rem !important;
  }
  .me-md-14,
  .mx-md-14 {
    margin-right: 12rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 12rem !important;
  }
  .ms-md-14,
  .mx-md-14 {
    margin-left: 12rem !important;
  }
  .m-md-15 {
    margin: 16rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 16rem !important;
  }
  .me-md-15,
  .mx-md-15 {
    margin-right: 16rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 16rem !important;
  }
  .ms-md-15,
  .mx-md-15 {
    margin-left: 16rem !important;
  }
  .m-md-16 {
    margin: 25rem !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 25rem !important;
  }
  .me-md-16,
  .mx-md-16 {
    margin-right: 25rem !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 25rem !important;
  }
  .ms-md-16,
  .mx-md-16 {
    margin-left: 25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pe-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .ps-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pe-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .ps-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pe-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .ps-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .pe-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .ps-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important;
  }
  .pe-md-4,
  .px-md-4 {
    padding-right: 1rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .ps-md-4,
  .px-md-4 {
    padding-left: 1rem !important;
  }
  .p-md-5 {
    padding: 1.5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important;
  }
  .pe-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important;
  }
  .p-md-6 {
    padding: 2rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important;
  }
  .pe-md-6,
  .px-md-6 {
    padding-right: 2rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important;
  }
  .ps-md-6,
  .px-md-6 {
    padding-left: 2rem !important;
  }
  .p-md-7 {
    padding: 2.5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 2.5rem !important;
  }
  .pe-md-7,
  .px-md-7 {
    padding-right: 2.5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 2.5rem !important;
  }
  .ps-md-7,
  .px-md-7 {
    padding-left: 2.5rem !important;
  }
  .p-md-8 {
    padding: 3rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3rem !important;
  }
  .pe-md-8,
  .px-md-8 {
    padding-right: 3rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3rem !important;
  }
  .ps-md-8,
  .px-md-8 {
    padding-left: 3rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pe-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .ps-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important;
  }
  .pe-md-10,
  .px-md-10 {
    padding-right: 5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important;
  }
  .ps-md-10,
  .px-md-10 {
    padding-left: 5rem !important;
  }
  .p-md-11 {
    padding: 6rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 6rem !important;
  }
  .pe-md-11,
  .px-md-11 {
    padding-right: 6rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 6rem !important;
  }
  .ps-md-11,
  .px-md-11 {
    padding-left: 6rem !important;
  }
  .p-md-12 {
    padding: 8rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 8rem !important;
  }
  .pe-md-12,
  .px-md-12 {
    padding-right: 8rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 8rem !important;
  }
  .ps-md-12,
  .px-md-12 {
    padding-left: 8rem !important;
  }
  .p-md-13 {
    padding: 10rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 10rem !important;
  }
  .pe-md-13,
  .px-md-13 {
    padding-right: 10rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 10rem !important;
  }
  .ps-md-13,
  .px-md-13 {
    padding-left: 10rem !important;
  }
  .p-md-14 {
    padding: 12rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 12rem !important;
  }
  .pe-md-14,
  .px-md-14 {
    padding-right: 12rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 12rem !important;
  }
  .ps-md-14,
  .px-md-14 {
    padding-left: 12rem !important;
  }
  .p-md-15 {
    padding: 16rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 16rem !important;
  }
  .pe-md-15,
  .px-md-15 {
    padding-right: 16rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 16rem !important;
  }
  .ps-md-15,
  .px-md-15 {
    padding-left: 16rem !important;
  }
  .p-md-16 {
    padding: 25rem !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 25rem !important;
  }
  .pe-md-16,
  .px-md-16 {
    padding-right: 25rem !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 25rem !important;
  }
  .ps-md-16,
  .px-md-16 {
    padding-left: 25rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .me-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ms-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .me-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ms-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important;
  }
  .me-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ms-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1rem !important;
  }
  .me-md-n4,
  .mx-md-n4 {
    margin-right: -1rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-md-n4,
  .mx-md-n4 {
    margin-left: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.5rem !important;
  }
  .me-md-n5,
  .mx-md-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-md-n5,
  .mx-md-n5 {
    margin-left: -1.5rem !important;
  }
  .m-md-n6 {
    margin: -2rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2rem !important;
  }
  .me-md-n6,
  .mx-md-n6 {
    margin-right: -2rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2rem !important;
  }
  .ms-md-n6,
  .mx-md-n6 {
    margin-left: -2rem !important;
  }
  .m-md-n7 {
    margin: -2.5rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -2.5rem !important;
  }
  .me-md-n7,
  .mx-md-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ms-md-n7,
  .mx-md-n7 {
    margin-left: -2.5rem !important;
  }
  .m-md-n8 {
    margin: -3rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3rem !important;
  }
  .me-md-n8,
  .mx-md-n8 {
    margin-right: -3rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n8,
  .mx-md-n8 {
    margin-left: -3rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .me-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ms-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -5rem !important;
  }
  .me-md-n10,
  .mx-md-n10 {
    margin-right: -5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-md-n10,
  .mx-md-n10 {
    margin-left: -5rem !important;
  }
  .m-md-n11 {
    margin: -6rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -6rem !important;
  }
  .me-md-n11,
  .mx-md-n11 {
    margin-right: -6rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -6rem !important;
  }
  .ms-md-n11,
  .mx-md-n11 {
    margin-left: -6rem !important;
  }
  .m-md-n12 {
    margin: -8rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -8rem !important;
  }
  .me-md-n12,
  .mx-md-n12 {
    margin-right: -8rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -8rem !important;
  }
  .ms-md-n12,
  .mx-md-n12 {
    margin-left: -8rem !important;
  }
  .m-md-n13 {
    margin: -10rem !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -10rem !important;
  }
  .me-md-n13,
  .mx-md-n13 {
    margin-right: -10rem !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -10rem !important;
  }
  .ms-md-n13,
  .mx-md-n13 {
    margin-left: -10rem !important;
  }
  .m-md-n14 {
    margin: -12rem !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -12rem !important;
  }
  .me-md-n14,
  .mx-md-n14 {
    margin-right: -12rem !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -12rem !important;
  }
  .ms-md-n14,
  .mx-md-n14 {
    margin-left: -12rem !important;
  }
  .m-md-n15 {
    margin: -16rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -16rem !important;
  }
  .me-md-n15,
  .mx-md-n15 {
    margin-right: -16rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -16rem !important;
  }
  .ms-md-n15,
  .mx-md-n15 {
    margin-left: -16rem !important;
  }
  .m-md-n16 {
    margin: -25rem !important;
  }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -25rem !important;
  }
  .me-md-n16,
  .mx-md-n16 {
    margin-right: -25rem !important;
  }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-md-n16,
  .mx-md-n16 {
    margin-left: -25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .me-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .me-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ms-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .me-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ms-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .me-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ms-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .me-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .ms-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important;
  }
  .me-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .ms-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important;
  }
  .me-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 2rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important;
  }
  .me-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important;
  }
  .ms-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important;
  }
  .m-lg-7 {
    margin: 2.5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 2.5rem !important;
  }
  .me-lg-7,
  .mx-lg-7 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 2.5rem !important;
  }
  .ms-lg-7,
  .mx-lg-7 {
    margin-left: 2.5rem !important;
  }
  .m-lg-8 {
    margin: 3rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3rem !important;
  }
  .me-lg-8,
  .mx-lg-8 {
    margin-right: 3rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3rem !important;
  }
  .ms-lg-8,
  .mx-lg-8 {
    margin-left: 3rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .me-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ms-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important;
  }
  .me-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important;
  }
  .ms-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important;
  }
  .m-lg-11 {
    margin: 6rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 6rem !important;
  }
  .me-lg-11,
  .mx-lg-11 {
    margin-right: 6rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 6rem !important;
  }
  .ms-lg-11,
  .mx-lg-11 {
    margin-left: 6rem !important;
  }
  .m-lg-12 {
    margin: 8rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 8rem !important;
  }
  .me-lg-12,
  .mx-lg-12 {
    margin-right: 8rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 8rem !important;
  }
  .ms-lg-12,
  .mx-lg-12 {
    margin-left: 8rem !important;
  }
  .m-lg-13 {
    margin: 10rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 10rem !important;
  }
  .me-lg-13,
  .mx-lg-13 {
    margin-right: 10rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 10rem !important;
  }
  .ms-lg-13,
  .mx-lg-13 {
    margin-left: 10rem !important;
  }
  .m-lg-14 {
    margin: 12rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 12rem !important;
  }
  .me-lg-14,
  .mx-lg-14 {
    margin-right: 12rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 12rem !important;
  }
  .ms-lg-14,
  .mx-lg-14 {
    margin-left: 12rem !important;
  }
  .m-lg-15 {
    margin: 16rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 16rem !important;
  }
  .me-lg-15,
  .mx-lg-15 {
    margin-right: 16rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 16rem !important;
  }
  .ms-lg-15,
  .mx-lg-15 {
    margin-left: 16rem !important;
  }
  .m-lg-16 {
    margin: 25rem !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 25rem !important;
  }
  .me-lg-16,
  .mx-lg-16 {
    margin-right: 25rem !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 25rem !important;
  }
  .ms-lg-16,
  .mx-lg-16 {
    margin-left: 25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pe-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .ps-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pe-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .ps-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pe-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .ps-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pe-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .ps-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important;
  }
  .pe-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .ps-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important;
  }
  .pe-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 2rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important;
  }
  .pe-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important;
  }
  .ps-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important;
  }
  .p-lg-7 {
    padding: 2.5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 2.5rem !important;
  }
  .pe-lg-7,
  .px-lg-7 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 2.5rem !important;
  }
  .ps-lg-7,
  .px-lg-7 {
    padding-left: 2.5rem !important;
  }
  .p-lg-8 {
    padding: 3rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3rem !important;
  }
  .pe-lg-8,
  .px-lg-8 {
    padding-right: 3rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-8,
  .px-lg-8 {
    padding-left: 3rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pe-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .ps-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important;
  }
  .pe-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important;
  }
  .ps-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important;
  }
  .p-lg-11 {
    padding: 6rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 6rem !important;
  }
  .pe-lg-11,
  .px-lg-11 {
    padding-right: 6rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 6rem !important;
  }
  .ps-lg-11,
  .px-lg-11 {
    padding-left: 6rem !important;
  }
  .p-lg-12 {
    padding: 8rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 8rem !important;
  }
  .pe-lg-12,
  .px-lg-12 {
    padding-right: 8rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 8rem !important;
  }
  .ps-lg-12,
  .px-lg-12 {
    padding-left: 8rem !important;
  }
  .p-lg-13 {
    padding: 10rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 10rem !important;
  }
  .pe-lg-13,
  .px-lg-13 {
    padding-right: 10rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 10rem !important;
  }
  .ps-lg-13,
  .px-lg-13 {
    padding-left: 10rem !important;
  }
  .p-lg-14 {
    padding: 12rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 12rem !important;
  }
  .pe-lg-14,
  .px-lg-14 {
    padding-right: 12rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 12rem !important;
  }
  .ps-lg-14,
  .px-lg-14 {
    padding-left: 12rem !important;
  }
  .p-lg-15 {
    padding: 16rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 16rem !important;
  }
  .pe-lg-15,
  .px-lg-15 {
    padding-right: 16rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 16rem !important;
  }
  .ps-lg-15,
  .px-lg-15 {
    padding-left: 16rem !important;
  }
  .p-lg-16 {
    padding: 25rem !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 25rem !important;
  }
  .pe-lg-16,
  .px-lg-16 {
    padding-right: 25rem !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 25rem !important;
  }
  .ps-lg-16,
  .px-lg-16 {
    padding-left: 25rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .me-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ms-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .me-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ms-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .me-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ms-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1rem !important;
  }
  .me-lg-n4,
  .mx-lg-n4 {
    margin-right: -1rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-lg-n4,
  .mx-lg-n4 {
    margin-left: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .me-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n6 {
    margin: -2rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2rem !important;
  }
  .me-lg-n6,
  .mx-lg-n6 {
    margin-right: -2rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2rem !important;
  }
  .ms-lg-n6,
  .mx-lg-n6 {
    margin-left: -2rem !important;
  }
  .m-lg-n7 {
    margin: -2.5rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -2.5rem !important;
  }
  .me-lg-n7,
  .mx-lg-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ms-lg-n7,
  .mx-lg-n7 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n8 {
    margin: -3rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3rem !important;
  }
  .me-lg-n8,
  .mx-lg-n8 {
    margin-right: -3rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n8,
  .mx-lg-n8 {
    margin-left: -3rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .me-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ms-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -5rem !important;
  }
  .me-lg-n10,
  .mx-lg-n10 {
    margin-right: -5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-lg-n10,
  .mx-lg-n10 {
    margin-left: -5rem !important;
  }
  .m-lg-n11 {
    margin: -6rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -6rem !important;
  }
  .me-lg-n11,
  .mx-lg-n11 {
    margin-right: -6rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -6rem !important;
  }
  .ms-lg-n11,
  .mx-lg-n11 {
    margin-left: -6rem !important;
  }
  .m-lg-n12 {
    margin: -8rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -8rem !important;
  }
  .me-lg-n12,
  .mx-lg-n12 {
    margin-right: -8rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -8rem !important;
  }
  .ms-lg-n12,
  .mx-lg-n12 {
    margin-left: -8rem !important;
  }
  .m-lg-n13 {
    margin: -10rem !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -10rem !important;
  }
  .me-lg-n13,
  .mx-lg-n13 {
    margin-right: -10rem !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -10rem !important;
  }
  .ms-lg-n13,
  .mx-lg-n13 {
    margin-left: -10rem !important;
  }
  .m-lg-n14 {
    margin: -12rem !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -12rem !important;
  }
  .me-lg-n14,
  .mx-lg-n14 {
    margin-right: -12rem !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -12rem !important;
  }
  .ms-lg-n14,
  .mx-lg-n14 {
    margin-left: -12rem !important;
  }
  .m-lg-n15 {
    margin: -16rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -16rem !important;
  }
  .me-lg-n15,
  .mx-lg-n15 {
    margin-right: -16rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -16rem !important;
  }
  .ms-lg-n15,
  .mx-lg-n15 {
    margin-left: -16rem !important;
  }
  .m-lg-n16 {
    margin: -25rem !important;
  }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -25rem !important;
  }
  .me-lg-n16,
  .mx-lg-n16 {
    margin-right: -25rem !important;
  }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-lg-n16,
  .mx-lg-n16 {
    margin-left: -25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .me-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ms-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .me-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ms-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .me-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ms-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .me-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ms-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important;
  }
  .me-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .ms-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important;
  }
  .me-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 2rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important;
  }
  .me-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important;
  }
  .ms-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important;
  }
  .m-xl-7 {
    margin: 2.5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 2.5rem !important;
  }
  .me-xl-7,
  .mx-xl-7 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 2.5rem !important;
  }
  .ms-xl-7,
  .mx-xl-7 {
    margin-left: 2.5rem !important;
  }
  .m-xl-8 {
    margin: 3rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3rem !important;
  }
  .me-xl-8,
  .mx-xl-8 {
    margin-right: 3rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3rem !important;
  }
  .ms-xl-8,
  .mx-xl-8 {
    margin-left: 3rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .me-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ms-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important;
  }
  .me-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important;
  }
  .ms-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important;
  }
  .m-xl-11 {
    margin: 6rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 6rem !important;
  }
  .me-xl-11,
  .mx-xl-11 {
    margin-right: 6rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 6rem !important;
  }
  .ms-xl-11,
  .mx-xl-11 {
    margin-left: 6rem !important;
  }
  .m-xl-12 {
    margin: 8rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 8rem !important;
  }
  .me-xl-12,
  .mx-xl-12 {
    margin-right: 8rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 8rem !important;
  }
  .ms-xl-12,
  .mx-xl-12 {
    margin-left: 8rem !important;
  }
  .m-xl-13 {
    margin: 10rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 10rem !important;
  }
  .me-xl-13,
  .mx-xl-13 {
    margin-right: 10rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 10rem !important;
  }
  .ms-xl-13,
  .mx-xl-13 {
    margin-left: 10rem !important;
  }
  .m-xl-14 {
    margin: 12rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 12rem !important;
  }
  .me-xl-14,
  .mx-xl-14 {
    margin-right: 12rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 12rem !important;
  }
  .ms-xl-14,
  .mx-xl-14 {
    margin-left: 12rem !important;
  }
  .m-xl-15 {
    margin: 16rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 16rem !important;
  }
  .me-xl-15,
  .mx-xl-15 {
    margin-right: 16rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 16rem !important;
  }
  .ms-xl-15,
  .mx-xl-15 {
    margin-left: 16rem !important;
  }
  .m-xl-16 {
    margin: 25rem !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 25rem !important;
  }
  .me-xl-16,
  .mx-xl-16 {
    margin-right: 25rem !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 25rem !important;
  }
  .ms-xl-16,
  .mx-xl-16 {
    margin-left: 25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pe-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .ps-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pe-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .ps-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pe-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .ps-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pe-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .ps-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important;
  }
  .pe-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .ps-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important;
  }
  .pe-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 2rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important;
  }
  .pe-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important;
  }
  .ps-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important;
  }
  .p-xl-7 {
    padding: 2.5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.5rem !important;
  }
  .pe-xl-7,
  .px-xl-7 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.5rem !important;
  }
  .ps-xl-7,
  .px-xl-7 {
    padding-left: 2.5rem !important;
  }
  .p-xl-8 {
    padding: 3rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3rem !important;
  }
  .pe-xl-8,
  .px-xl-8 {
    padding-right: 3rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-8,
  .px-xl-8 {
    padding-left: 3rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pe-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .ps-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important;
  }
  .pe-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important;
  }
  .ps-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important;
  }
  .p-xl-11 {
    padding: 6rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 6rem !important;
  }
  .pe-xl-11,
  .px-xl-11 {
    padding-right: 6rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 6rem !important;
  }
  .ps-xl-11,
  .px-xl-11 {
    padding-left: 6rem !important;
  }
  .p-xl-12 {
    padding: 8rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 8rem !important;
  }
  .pe-xl-12,
  .px-xl-12 {
    padding-right: 8rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 8rem !important;
  }
  .ps-xl-12,
  .px-xl-12 {
    padding-left: 8rem !important;
  }
  .p-xl-13 {
    padding: 10rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 10rem !important;
  }
  .pe-xl-13,
  .px-xl-13 {
    padding-right: 10rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 10rem !important;
  }
  .ps-xl-13,
  .px-xl-13 {
    padding-left: 10rem !important;
  }
  .p-xl-14 {
    padding: 12rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 12rem !important;
  }
  .pe-xl-14,
  .px-xl-14 {
    padding-right: 12rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 12rem !important;
  }
  .ps-xl-14,
  .px-xl-14 {
    padding-left: 12rem !important;
  }
  .p-xl-15 {
    padding: 16rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 16rem !important;
  }
  .pe-xl-15,
  .px-xl-15 {
    padding-right: 16rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 16rem !important;
  }
  .ps-xl-15,
  .px-xl-15 {
    padding-left: 16rem !important;
  }
  .p-xl-16 {
    padding: 25rem !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 25rem !important;
  }
  .pe-xl-16,
  .px-xl-16 {
    padding-right: 25rem !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 25rem !important;
  }
  .ps-xl-16,
  .px-xl-16 {
    padding-left: 25rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .me-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ms-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .me-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ms-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .me-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ms-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1rem !important;
  }
  .me-xl-n4,
  .mx-xl-n4 {
    margin-right: -1rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-xl-n4,
  .mx-xl-n4 {
    margin-left: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .me-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n6 {
    margin: -2rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2rem !important;
  }
  .me-xl-n6,
  .mx-xl-n6 {
    margin-right: -2rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2rem !important;
  }
  .ms-xl-n6,
  .mx-xl-n6 {
    margin-left: -2rem !important;
  }
  .m-xl-n7 {
    margin: -2.5rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -2.5rem !important;
  }
  .me-xl-n7,
  .mx-xl-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ms-xl-n7,
  .mx-xl-n7 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n8 {
    margin: -3rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3rem !important;
  }
  .me-xl-n8,
  .mx-xl-n8 {
    margin-right: -3rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n8,
  .mx-xl-n8 {
    margin-left: -3rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .me-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ms-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -5rem !important;
  }
  .me-xl-n10,
  .mx-xl-n10 {
    margin-right: -5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-xl-n10,
  .mx-xl-n10 {
    margin-left: -5rem !important;
  }
  .m-xl-n11 {
    margin: -6rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -6rem !important;
  }
  .me-xl-n11,
  .mx-xl-n11 {
    margin-right: -6rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -6rem !important;
  }
  .ms-xl-n11,
  .mx-xl-n11 {
    margin-left: -6rem !important;
  }
  .m-xl-n12 {
    margin: -8rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -8rem !important;
  }
  .me-xl-n12,
  .mx-xl-n12 {
    margin-right: -8rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -8rem !important;
  }
  .ms-xl-n12,
  .mx-xl-n12 {
    margin-left: -8rem !important;
  }
  .m-xl-n13 {
    margin: -10rem !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -10rem !important;
  }
  .me-xl-n13,
  .mx-xl-n13 {
    margin-right: -10rem !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -10rem !important;
  }
  .ms-xl-n13,
  .mx-xl-n13 {
    margin-left: -10rem !important;
  }
  .m-xl-n14 {
    margin: -12rem !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -12rem !important;
  }
  .me-xl-n14,
  .mx-xl-n14 {
    margin-right: -12rem !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -12rem !important;
  }
  .ms-xl-n14,
  .mx-xl-n14 {
    margin-left: -12rem !important;
  }
  .m-xl-n15 {
    margin: -16rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -16rem !important;
  }
  .me-xl-n15,
  .mx-xl-n15 {
    margin-right: -16rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -16rem !important;
  }
  .ms-xl-n15,
  .mx-xl-n15 {
    margin-left: -16rem !important;
  }
  .m-xl-n16 {
    margin: -25rem !important;
  }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -25rem !important;
  }
  .me-xl-n16,
  .mx-xl-n16 {
    margin-right: -25rem !important;
  }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-xl-n16,
  .mx-xl-n16 {
    margin-left: -25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.mw-25 {
  max-width: 25% !important;
}

.vw-25 {
  width: 25vw !important;
}

.mw-50 {
  max-width: 50% !important;
}

.vw-50 {
  width: 50vw !important;
}

.mw-75 {
  max-width: 75% !important;
}

.vw-75 {
  width: 75vw !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.mw-auto {
  max-width: auto !important;
}

.vw-auto {
  width: autovw !important;
}

.mw-110 {
  max-width: 110% !important;
}

.vw-110 {
  width: 110vw !important;
}

.mw-120 {
  max-width: 120% !important;
}

.vw-120 {
  width: 120vw !important;
}

.mw-130 {
  max-width: 130% !important;
}

.vw-130 {
  width: 130vw !important;
}

.mw-140 {
  max-width: 140% !important;
}

.vw-140 {
  width: 140vw !important;
}

.mw-150 {
  max-width: 150% !important;
}

.vw-150 {
  width: 150vw !important;
}

@media (min-width: 576px) {
  .h-25 {
    height: 25% !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .mw-25 {
    max-width: 25% !important;
  }
  .vw-25 {
    width: 25vw;
  }
  .h-50 {
    height: 50% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .mw-50 {
    max-width: 50% !important;
  }
  .vw-50 {
    width: 50vw;
  }
  .h-75 {
    height: 75% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .mw-75 {
    max-width: 75% !important;
  }
  .vw-75 {
    width: 75vw;
  }
  .h-100 {
    height: 100% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .vw-100 {
    width: 100vw;
  }
  .h-auto {
    height: auto !important;
  }
  .w-auto {
    width: auto !important;
  }
  .mw-auto {
    max-width: auto !important;
  }
  .vw-auto {
    width: autovw;
  }
  .h-110 {
    height: 110% !important;
  }
  .w-110 {
    width: 110% !important;
  }
  .mw-110 {
    max-width: 110% !important;
  }
  .vw-110 {
    width: 110vw;
  }
  .h-120 {
    height: 120% !important;
  }
  .w-120 {
    width: 120% !important;
  }
  .mw-120 {
    max-width: 120% !important;
  }
  .vw-120 {
    width: 120vw;
  }
  .h-130 {
    height: 130% !important;
  }
  .w-130 {
    width: 130% !important;
  }
  .mw-130 {
    max-width: 130% !important;
  }
  .vw-130 {
    width: 130vw;
  }
  .h-140 {
    height: 140% !important;
  }
  .w-140 {
    width: 140% !important;
  }
  .mw-140 {
    max-width: 140% !important;
  }
  .vw-140 {
    width: 140vw;
  }
  .h-150 {
    height: 150% !important;
  }
  .w-150 {
    width: 150% !important;
  }
  .mw-150 {
    max-width: 150% !important;
  }
  .vw-150 {
    width: 150vw;
  }
}

@media (min-width: 768px) {
  .h-md-25 {
    height: 25% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .mw-md-25 {
    max-width: 25% !important;
  }
  .vw-md-25 {
    width: 25vw;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .mw-md-50 {
    max-width: 50% !important;
  }
  .vw-md-50 {
    width: 50vw;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .mw-md-75 {
    max-width: 75% !important;
  }
  .vw-md-75 {
    width: 75vw;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .mw-md-100 {
    max-width: 100% !important;
  }
  .vw-md-100 {
    width: 100vw;
  }
  .h-md-auto {
    height: auto !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .mw-md-auto {
    max-width: auto !important;
  }
  .vw-md-auto {
    width: autovw;
  }
  .h-md-110 {
    height: 110% !important;
  }
  .w-md-110 {
    width: 110% !important;
  }
  .mw-md-110 {
    max-width: 110% !important;
  }
  .vw-md-110 {
    width: 110vw;
  }
  .h-md-120 {
    height: 120% !important;
  }
  .w-md-120 {
    width: 120% !important;
  }
  .mw-md-120 {
    max-width: 120% !important;
  }
  .vw-md-120 {
    width: 120vw;
  }
  .h-md-130 {
    height: 130% !important;
  }
  .w-md-130 {
    width: 130% !important;
  }
  .mw-md-130 {
    max-width: 130% !important;
  }
  .vw-md-130 {
    width: 130vw;
  }
  .h-md-140 {
    height: 140% !important;
  }
  .w-md-140 {
    width: 140% !important;
  }
  .mw-md-140 {
    max-width: 140% !important;
  }
  .vw-md-140 {
    width: 140vw;
  }
  .h-md-150 {
    height: 150% !important;
  }
  .w-md-150 {
    width: 150% !important;
  }
  .mw-md-150 {
    max-width: 150% !important;
  }
  .vw-md-150 {
    width: 150vw;
  }
}

@media (min-width: 992px) {
  .h-lg-25 {
    height: 25% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .mw-lg-25 {
    max-width: 25% !important;
  }
  .vw-lg-25 {
    width: 25vw;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .mw-lg-50 {
    max-width: 50% !important;
  }
  .vw-lg-50 {
    width: 50vw;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .mw-lg-75 {
    max-width: 75% !important;
  }
  .vw-lg-75 {
    width: 75vw;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .mw-lg-100 {
    max-width: 100% !important;
  }
  .vw-lg-100 {
    width: 100vw;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .mw-lg-auto {
    max-width: auto !important;
  }
  .vw-lg-auto {
    width: autovw;
  }
  .h-lg-110 {
    height: 110% !important;
  }
  .w-lg-110 {
    width: 110% !important;
  }
  .mw-lg-110 {
    max-width: 110% !important;
  }
  .vw-lg-110 {
    width: 110vw;
  }
  .h-lg-120 {
    height: 120% !important;
  }
  .w-lg-120 {
    width: 120% !important;
  }
  .mw-lg-120 {
    max-width: 120% !important;
  }
  .vw-lg-120 {
    width: 120vw;
  }
  .h-lg-130 {
    height: 130% !important;
  }
  .w-lg-130 {
    width: 130% !important;
  }
  .mw-lg-130 {
    max-width: 130% !important;
  }
  .vw-lg-130 {
    width: 130vw;
  }
  .h-lg-140 {
    height: 140% !important;
  }
  .w-lg-140 {
    width: 140% !important;
  }
  .mw-lg-140 {
    max-width: 140% !important;
  }
  .vw-lg-140 {
    width: 140vw;
  }
  .h-lg-150 {
    height: 150% !important;
  }
  .w-lg-150 {
    width: 150% !important;
  }
  .mw-lg-150 {
    max-width: 150% !important;
  }
  .vw-lg-150 {
    width: 150vw;
  }
}

@media (min-width: 1200px) {
  .h-xl-25 {
    height: 25% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .mw-xl-25 {
    max-width: 25% !important;
  }
  .vw-xl-25 {
    width: 25vw;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .mw-xl-50 {
    max-width: 50% !important;
  }
  .vw-xl-50 {
    width: 50vw;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .mw-xl-75 {
    max-width: 75% !important;
  }
  .vw-xl-75 {
    width: 75vw;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .mw-xl-100 {
    max-width: 100% !important;
  }
  .vw-xl-100 {
    width: 100vw;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .mw-xl-auto {
    max-width: auto !important;
  }
  .vw-xl-auto {
    width: autovw;
  }
  .h-xl-110 {
    height: 110% !important;
  }
  .w-xl-110 {
    width: 110% !important;
  }
  .mw-xl-110 {
    max-width: 110% !important;
  }
  .vw-xl-110 {
    width: 110vw;
  }
  .h-xl-120 {
    height: 120% !important;
  }
  .w-xl-120 {
    width: 120% !important;
  }
  .mw-xl-120 {
    max-width: 120% !important;
  }
  .vw-xl-120 {
    width: 120vw;
  }
  .h-xl-130 {
    height: 130% !important;
  }
  .w-xl-130 {
    width: 130% !important;
  }
  .mw-xl-130 {
    max-width: 130% !important;
  }
  .vw-xl-130 {
    width: 130vw;
  }
  .h-xl-140 {
    height: 140% !important;
  }
  .w-xl-140 {
    width: 140% !important;
  }
  .mw-xl-140 {
    max-width: 140% !important;
  }
  .vw-xl-140 {
    width: 140vw;
  }
  .h-xl-150 {
    height: 150% !important;
  }
  .w-xl-150 {
    width: 150% !important;
  }
  .mw-xl-150 {
    max-width: 150% !important;
  }
  .vw-xl-150 {
    width: 150vw;
  }
}

.fw-light {
  font-weight: 300 !important;
}
