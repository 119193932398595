// Custom Variables
@import 'user-variables';

// Theme Helpers: Variables, Mixins and Placeholders
@import 'helpers';

// Bootstrap Core
@import '../../node_modules/bootstrap/scss/bootstrap';

// Theme Core
@import 'reboot';
@import 'components';
@import 'utilities';

// Custom Core
@import 'user';

// Navbar mobile menu styles
@include media-breakpoint-down(lg) {
  .navbar-collapse {
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    padding: 1rem;
    margin: 0 -0.75rem;
  }
  .navbar-dark .navbar-collapse {
    background-color: rgba(var(--bs-dark-rgb), 1) !important;
  }
  .navbar-light .navbar-collapse {
    background-color: rgba(var(--bs-light-rgb), 1) !important;
  }
}
