// 
// user-variables.scss
// Use this to override theme variables
//

// Variables
// --------------------------------------------------

//== Colors
//
// Gray and brand colors to use across the Theme.

// Grayscale
$white:                rgba(255,255,255,0.92) !default;
$gray-100:             #F7F9FA !default;
$gray-200:             #EAECEE !default;
$gray-300:             #DFE2E5 !default;
$gray-400:             #D2D6DA !default;
$gray-500:             #B0B8BF !default;
$gray-600:             #9AA3AC !default;
$gray-700:             #6E7A87 !default;
$gray-800:             #57616B !default;
$gray-900:             #012140 !default;
$black:                #161C2D !default;

// Bluescale
$blue-100:             #EBF4FF !default;
$blue-200:             #B1D6FF !default;
$blue-300:             #76B8FE !default;
$blue-400:             #3B9AFE !default;
$blue-500:             #1F8BFF !default;
$blue-600:             #0069D8 !default;
$blue-700:             #00458F !default;
$blue-800:             #003166 !default;
$blue-900:             #011428 !default;
$blue-indigo:        #2F4660 !default;
$blue-indigo-hover:  #141E29 !default;

// Brand colors
$primary:               #007bfd !default;
$secondary:             $gray-100 !default;
$info:		            #745FDE !default;
$success:               #0AA67C !default;
$warning:               #F3C43F !default;
$danger:                #CD2B51 !default;
$light:                 $white !default;
$dark:                  $gray-900 !default;
$bs-danger:           #ef476f !default;

// Gradient
$gradient-from-color:   $blue-800;
$gradient-to-color:     $blue-400;



// Body

$body-color:            $gray-900 !default;
$body-bg:               $white !default;
$text-muted:            $gray-400;
$text-semimuted:        $gray-800 !default;

// Code

$kbd-bg:                 $gray-900 !default;


// Other 

$border-light-color:          $gray-100 !default;


// Border Radius 

$border-radius:               .25rem !default;
$border-radius-lg:            1rem !default;
$border-radius-sm:            .5rem !default;
$border-radius-xs:            .375rem !default;
